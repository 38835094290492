<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout column fill-height>
		<v-layout v-if="initialLoading" align-center justify-center fill-height>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>

		<v-container v-if="!initialLoading && hasInitialItems">
			<v-layout align-center justify-space-between row mb-2>
				<v-flex xs6>
					<w-search-input
						v-if="searchable"
						v-model="filters.search"
						:placeholder="searchable.placeholder ?? $t(transKey+'.search')" />
				</v-flex>
				<slot name="extra-main-actions"></slot>
				<w-btn
					v-if="hasCreateAction"
					icon="add"
					:mini="$vuetify.breakpoint.xsOnly"
					:small="$vuetify.breakpoint.xsOnly"
					@click="$emit('item-create')"
				>
					{{ $t(`${transKey}.actions.create`) }}
				</w-btn>
			</v-layout>

			<v-data-table
				v-if="!initialLoading"
				class="elevation-1"
				:headers="mappedHeaders"
				:items="items"
				item-key="id"
				:loading="loading"
				:total-items="paginable === 'server' ? pagination.totalItems : null"
				:rows-per-page-items="pagination.rowsPerPageItems"
				:pagination.sync="paginationProp"
				:search="paginable === 'server' ? undefined : filters.search"
			>
				<template v-slot:items="{ selected, item }">
					<tr
						:active="selected"
						class="pointer"
						@click="triggerAction('edit', item)"
					>
						<td v-html="$highlightMatching(item.name, filters.search)"></td>
						<td class="text-xs-center">
							<slot name="item-actions" :item="item">
								<w-btn-edit
									v-if="actions.edit"
									mini
									@click.stop="triggerAction('edit', item)"
								/>
								<w-btn-delete
									v-if="actions.delete"
									:disabled="!!item.deleted_at"
									:loading="item.deletion_loading || !!item.deleted_at"
									mini
									@click.stop="triggerAction('delete', item)"
								/>
								<w-btn-view
									v-if="actions.view"
									mini
									@click.stop="triggerAction('view', item)"
								/>
							</slot>
							<slot name="extra-item-actions" :item="item"/>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-container>

		<slot v-else-if="!loading" name="no-data" />
	</v-layout>
</template>


<script>
/**
 * @displayName w-resource
 *
 * @since 1.47.5
 */
import debounce from 'debounce';
import PaginationMixin from "@/mixins/PaginationMixin";
import AbstractModuleGuard from "@/mixins/ModulesGuards/AbstractModuleGuard";

export default {
	name: 'WResource',
	mixins: [
		AbstractModuleGuard, // abstract module guard is required to send events with proper singleton bus
		PaginationMixin
	],
	props: {
		actions: {
			type: Object,
			default: () => ({
				create: true,
				delete: false,
				edit: true,
				list: () => Promise.resolve([]),
			}),
		},
		debounce: {
			type: Number,
			default: 300,
		},
		headers: {
			type: Object,
			default: () => ({
				items: [],
				showId: false,
			}),
		},
		paginable: {
			type: String,
			default: 'server',
		},
		resourceService: {
			type: Object,
			required: false,
			default: null,
		},
		searchable: {
			type: [Boolean, Object],
			default: true,
		},
		transKey: {
			type: String,
			default: null,
		},
		nameKey: {
			type: String,
			default: 'name',
		},
	},
	data() {
		return {
			debouncedLoadItems: () => {},
			filters: {
				search: ''
			},
			initialLoading: true,
			loading: true,
			items: [],
			itemFields: [],
			hasInitialItems: false,
			defaultPaginationData: {
				descending: false,
				page: 1,
				rowsPerPage: -1,
				rowsPerPageItems: [5, 10, 15, 20, 25, 50, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}],
				sortBy: 'name',
				totalItems: 0,
			},
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: -1,
				rowsPerPageItems: [5, 10, 15, 20, 25, 50, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}],
				sortBy: 'name',
				totalItems: 0,
			},
			tableHeaders: [],
			isResetting: false,
		};
	},
	computed: {
		// AbstractModuleGuard is required to send events with proper singleton bus
		hasModule: function () {
			return true
		},
		hasActions() {
			return Object.entries(this.actions)
				.filter(([key, value]) => key !== 'list' && value)
				.length > 0;
		},
		hasCreateAction() {
			return this.actions.create;
		},
		headerMapper() {
			if (this.headers.items.length > 0) {
				return () => {
					return this.headers.items.map(header => {
						return {
							text: header.text ?? (this.transKey ? this.$t(this.transKey + '.' + header.value) : header.value),
							value: header.value,
							sortable: header.sortable ?? false,
						}
					})
				}
			}

			return item => {
				return Object.keys(item)
					.filter(key => (this.headers.showId || key !== 'id') && this.itemFields.includes(key))
					.map((key) => ({
						text: this.transKey ? this.$t(this.transKey + '.' + key) : key,
						value: key,
					}));
			}
		},
		mappedHeaders() {
			if (this.items.length === 0) {
				return [];
			}
			return [
				...this.headerMapper(this.items[0]),
				this.hasActions ? { text: 'Actions', value: 'actions', sortable: false, align: 'center' } : [],
			];
		},
		paginationProp: {
			get() {
				return this.paginable === 'server' ? this.pagination : undefined;
			},
			set(value) {
				if (this.paginable === 'server') {
					this.pagination = value;
				}
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				if (this.paginable === 'server') {
					this.debouncedLoadItems(true);
				}
			},
		},
		pagination: {
			deep: true,
			handler(newValue, oldValue) {
				for (const key of Object.keys(newValue)) {
					if (newValue[key] !== oldValue[key]) {
						this.loadItems(true);
						break;
					}
				}
			},
		},
	},
	created() {
		if (this.paginable === 'server') {
			this.debouncedLoadItems = debounce(skipCheck => this.loadItems(skipCheck), this.debounce);
		}
		this.loadItems();
	},
	methods: {
		reset() {
			this.hasLoaded = false;
			this.loading = true;
			this.initialLoading = true;
			this.hasInitialItems = false;
			this.filters.search = '';
			this.items = [];
			this.resetPagination()
			this.tableHeaders = [];
		},
		async loadItems(skipReset = false) {
			this.loading = true;
			try {
				const filters = {
					page: this.pagination.page,
					rowsPerPage: this.pagination.rowsPerPage,
					...(this.pagination.sortBy && { sort: (this.pagination.descending ? '-' : '+') + this.pagination.sortBy }),
					...(this.filters.search && { search: this.filters.search })
				}
				const data = await this.actions.list(filters);
				if (!skipReset && data === undefined) {
					// the request was canceled or something went wrong
					this.isResetting = true;
					return;
				}

				if ('pagination' in data) {
					this.items = data.data;
					this.setPagination(data.pagination);
				} else {
					this.items = data;
				}

				if (this.items.length > 0) {
					this.itemFields = Object.keys(this.items[0])
				}

				if (!this.hasLoaded) {
					this.hasInitialItems = this.items.length > 0;
					this.hasLoaded = true;
				}
			} finally {
				this.$emit('items-loaded');
				this.handlePostRequest();
			}
		},
		/**
		 * This method is called after the request is done
		 * It is used to reset the component if the request was canceled or something went wrong.
		 * If the request was canceled, it probably means that the parent component was a keep-alive component
		 * being reused. In this case, the component resets to avoid displaying the previous data.
		 */
		handlePostRequest() {
			if (this.isResetting) {
				this.isResetting = false;
				this.reset();
				return;
			}
			this.initialLoading = false;
			this.loading = false;
		},
		async triggerAction(action, item) {
			if (action === 'delete') {
				const confirmed = await this.$dialog.confirm({
					text: this.$t(this.transKey + '.actions.delete.confirmation_text', { name: item[this.nameKey] }),
					title: this.$t(this.transKey + '.actions.delete.confirmation_title', { name: item[this.nameKey] }),
					actions: {
						false: this.$t('actions.cancel'),
						true: {
							text: this.$t('actions.delete'),
							color: 'error',
							flat: false,
						}
					}
				});
				if (! confirmed) {
					return;
				}

				this.$set(item, 'deletion_loading', true);
				try {
					await this.actions[action](item);
					const itemIndex = this.items.findIndex(({ id }) => id == item.id);
					if (itemIndex >= 0) {
						this.items.splice(itemIndex, 1);
					}
					if (this.items.length === 0) {
						this.hasInitialItems = false;
					}
					this.$emit('item-deleted', item);
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t(this.transKey + '.deleted'));
				} catch (error) {
					delete item.deletion_loading;
					throw error;
				}
				return;
			}

			this.$emit(`item-${action}`, item);
		},
		addItem(item) {
			this.items.push(item)
			this.hasInitialItems = true
		},
		replaceItem(item) {
			const index = this.items.findIndex(({ id }) => id == item.id)
			index >= 0 ? this.items.splice(index, 1, item) : this.addItem(item)
		}
	},
};
</script>

<style scoped>
.pointer {
	cursor: pointer;
}
</style>
