export default {
	name: 'PaginationMixin',
	data: function () {
		return {
			defaultPaginationData: {
				page: 1,
				rowsPerPage: 15,
				totalItems: 0
			},
			pagination: {
				page: 1,
				rowsPerPage: 15,
				totalItems: 0
			}
		}
	},
	methods: {
		resetPagination: function () {
			this.pagination = { ...this.defaultPaginationData }
		},
		setPagination: function (pagination) {
			this.pagination.page = pagination.currentPage
			this.pagination.rowsPerPage = pagination.perPage
			this.pagination.totalItems = pagination.total
		},
		setPaginationPage: function (pageNumber) {
			this.pagination.page = pageNumber
			this.onPaginationUpdated()
		},
		setPaginationRowsPerPage: function (rowsPerPage) {
			this.pagination.rowsPerPage = rowsPerPage
			this.onPaginationUpdated()
		}
	}
}
